







































import { Component, Watch } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';
import moment from 'moment';
import DutyTableComponent from '@/pages/dashboard/duty-management/duty-table.vue';
import DutyManagementService from '@common-src/service/duty-management';
import { enableSubTypeDefaulter } from 'node_modules/echarts/lib/util/component';

@Component({
    components: {
        'duty-table': DutyTableComponent
    }
})
export default class DutyMangementDetailComponent extends BaseComponent {
    title: string = '';
    detailId: string = undefined;
    dutyData: Array<any> = [];
    selectedMonth: string = moment().format('YYYY-MM');

    // 班次信息
    shifts: Array<{shiftName:string, shiftId:string, startTime:string, endTime: string, timeSpan:string}> = [];
    shiftVisible: boolean = false;

    // 选中信息
    selectedData: any = undefined;
    selectedDay: any = undefined;
    selectedDutyInfo: {shiftName:string, shiftId:string} = { shiftName: '', shiftId: '' };

    @Watch('selectedMonth')
    update(newVal) {
        // 重新获取数据
        this.getDutyData();
    }

    created() {
        // 获取排班管理ID
        this.detailId = this.$route.params.id;
        this.getDutyData();
    }

    getDutyData() {
        this.startFullScreenLoading();
        DutyManagementService.design(this.detailId, this.selectedMonth).then(res => {
            this.title = res.departmentName;
            this.shifts = res.shifts;
            _.forEach(this.shifts, item => {
                if (moment(item.startTime).format('YYYY-MM-DD') !== moment(item.endTime).format('YYYY-MM-DD')) {
                    item.timeSpan = moment(item.startTime).format('HH:mm') + ' - 次日' + moment(item.endTime).format('HH:mm');
                } else {
                    item.timeSpan = moment(item.startTime).format('HH:mm') + ' - ' + moment(item.endTime).format('HH:mm');
                }
            });
            this.shifts.push({ shiftId: 'rest', shiftName: '休息', startTime: '', endTime: '', timeSpan: '休息' });
            this.dutyData = _.map(res.userShifts, item => {
                item.userId = item.user.id;
                item.userName = item.user.name;
                return item;
            });
        }).catch(err => {
            this.showMessageError(err);
        }).finally(() => {
            this.stopFullScreenLoading();
        });
    }

    save() {
        // 保存
        const paramData = _.cloneDeep(this.dutyData);
        _.map(paramData, item => {
            delete item.user;
            delete item.userName;
        });
        DutyManagementService.saveDesign(this.detailId, this.selectedMonth, paramData).then(res => {
            this.getDutyData();
        });
    }

    cancel() {
        // 取消
        this.$router.push('/dashboard/duty-management');
    }

    editDuty(data, day, dutyInfo) {
        this.shiftVisible = true;
        this.selectedData = data;
        this.selectedDay = day;
        this.selectedDutyInfo = dutyInfo;
    }

    confirmShift(shiftItem) {
        const matchUserDataList = _.filter(this.dutyData, item => { return item.userId === this.selectedData.userId; });
        if (matchUserDataList && matchUserDataList.length > 0) {
            let matchUserData = matchUserDataList[0];

            const matchDays = _.filter(matchUserData.shiftDates, item => { return item.day === this.selectedDay.day; });
            if (matchDays && matchDays.length > 0) {
                _.forEach(matchDays, item => {
                    item.shiftId = shiftItem.shiftId;
                    item.shiftName = shiftItem.shiftName;
                });
            } else {
                matchUserData.shiftDates.push({
                    shiftId: shiftItem.shiftId,
                    shiftName: shiftItem.shiftName,
                    month: this.selectedMonth,
                    day: this.selectedDay.day
                });
            }
        }
        this.shiftVisible = false;
    }

    cancelShift() {
        this.shiftVisible = false;
    }
}
