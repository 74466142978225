var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "duty-management-detail-component table-component" },
    [
      _c("div", { staticClass: "page-header" }, [
        _c(
          "div",
          { staticClass: "page-breadcrumb" },
          [
            _c(
              "a-breadcrumb",
              [
                _c(
                  "a-breadcrumb-item",
                  [_c("router-link", { attrs: { to: "/" } }, [_vm._v("首页")])],
                  1
                ),
                _c(
                  "a-breadcrumb-item",
                  [
                    _c("router-link", { attrs: { to: "/" } }, [
                      _vm._v("值班管理"),
                    ]),
                  ],
                  1
                ),
                _c(
                  "a-breadcrumb-item",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/dashboard/duty-management" } },
                      [_vm._v("值班管理")]
                    ),
                  ],
                  1
                ),
                _c("a-breadcrumb-item", [_vm._v(_vm._s(_vm.title))]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "page-body page-body-margin" },
        [
          _c("div", { staticClass: "header-wrapper" }, [
            _c(
              "div",
              { staticClass: "header-left-wrapper" },
              [
                _c("a-month-picker", {
                  attrs: { placeholder: "选择月份" },
                  model: {
                    value: _vm.selectedMonth,
                    callback: function ($$v) {
                      _vm.selectedMonth = $$v
                    },
                    expression: "selectedMonth",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "header-middle-wrapper" },
              _vm._l(_vm.shifts, function (shift) {
                return _c("div", { key: shift.shiftId }, [
                  _vm._v(_vm._s(shift.shiftName + "：" + shift.timeSpan)),
                ])
              }),
              0
            ),
            _c(
              "div",
              { staticClass: "header-right-wrapper" },
              [
                _c(
                  "jtl-button",
                  {
                    staticClass: "table-header-button",
                    attrs: { type: "primary" },
                    on: { click: _vm.save },
                  },
                  [_vm._v("保存")]
                ),
                _c(
                  "jtl-button",
                  {
                    staticClass: "table-header-button",
                    on: { click: _vm.cancel },
                  },
                  [_vm._v("取消")]
                ),
              ],
              1
            ),
          ]),
          _c("duty-table", {
            attrs: {
              "duty-data": _vm.dutyData,
              "selected-month": _vm.selectedMonth,
            },
            on: { cellClicked: _vm.editDuty },
          }),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: "选择班次",
            visible: _vm.shiftVisible,
            width: "200px",
            footer: "",
          },
          on: { cancel: _vm.cancelShift },
        },
        _vm._l(_vm.shifts, function (item, shiftIndex) {
          return _c(
            "div",
            {
              key: shiftIndex,
              staticClass: "shift-option",
              style: {
                color:
                  _vm.selectedDutyInfo.shiftId === item.shiftId
                    ? "#fff"
                    : "#333",
                background:
                  _vm.selectedDutyInfo.shiftId === item.shiftId
                    ? "#cdcdcd"
                    : "#fff",
              },
              on: {
                click: function ($event) {
                  return _vm.confirmShift(item)
                },
              },
            },
            [_vm._v(_vm._s(item.shiftName))]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }